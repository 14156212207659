<template>
	<div style="display: flex;align-items: center;justify-content: center;width: 100%;height: 100vh;">
		<div v-if="flage">
			<div>
				<img src="https://dgjmpgj.oss-cn-shanghai.aliyuncs.com/static/success.gif" alt="">
			</div>
			<div style="width: 100%;text-align: center;color: green;margin-top: 20px;font-weight: bold;">已成功关注！</div>
		</div>
	</div>
</template>

<script>
	// const axi = require('../assets/axi.js')
	import axios from 'axios'
	export default {
		data() {
			return {
				flage: false
			}
		},
		mounted() {
			// let url = 'http://www.rhdgj.com/?code=02153K000TFmlQ1k7W000YjmTd353K0p&state=31#/transfer'
			let url = window.location.href
			if (url.split('?')[1]) {
				let urlquery = url.split('?')[1]
				let code = urlquery.split('code=')[1]
				code = code.split('&state')[0]
				let ID = urlquery.split('&state=')[1]
				ID = ID.split('#/')[0]
				console.log(code)
				console.log(ID)
				let data = {
					code: code,
					UID: ID
				}
				console.log(data)
				axios.post('https://dzgmpgjapi.ronghengzn.com/api/Applet/IsFan', data).then(res => {
					console.log(res)
					if (res.data.data.Dto == 1) {
						this.flage = true
					} else {
						window.location.href = 'https://mp.weixin.qq.com/s/XIk38uSo5rc0jHQAJNMJvg'
					}
				}).catch(function(error) {
					console.log(error)
					if (error.response) {
						if (error.response.status == 500) {
							loading.close();
							Message({
								message: '服务器错误',
								type: 'error'
							});

						}
					}
				})
			}

		}
	}
</script>

<style>
</style>
